import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import smeProductService from "./smeProductService";

export const getSmeProductByReferralCodeThunk = createAsyncThunk(
  "sme/product/getProductByReferralCode",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeProductService.getSmeProductByReferralCode(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
