import groupBy from "lodash.groupby";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { ApplicationStepStatusEnum } from "../../enums/applicationStepStatus";
import {
  createApplicationDraftThunk,
  getApplicationStepStatusThunk,
} from "../../services/applicationService/applicationThunk";
import { getFilesByEntityIdThunk } from "../../services/fileService/fileThunk";
import {
  createSmeCompanyApplication,
  createSmeIndividualApplication,
  createSmePartnershipApplication,
  createSmeSolePropApplication,
  getSmeApplicationDetailsThunk,
  patchSmeApplicationToPendingThunk,
} from "../../services/smeApplicationService/smeApplicationThunk";
import { ORGANIZATION_TYPE } from "../../enums/partnerType";
import { SmeAgreement } from "../smeAgreement/smeAgreementSlice";
import { SmeCompanyDirector, SmePartnershipDirector } from "./smeDirectorSlice";
import { getRequiredFileByPartnerTypeThunk } from "../../services/smeFileService/smeFileThunk";

export type PartnerDetailsType = {
  username: string;
  organizationType: ORGANIZATION_TYPE;
};

export type CreateSmeApplicationDraftPayload = {
  id?: string;
  applicationNo?: string;
  redOneUsername: string;
  status?: string;
  loanReason: string;
  appliedLoanInterestRate: number;
  appliedLoanTenure: number;
  appliedLoanAmount: number;
  partnerType: string;
  promoCode: string | null;
  smeIndividualApplicationId: string | null;
  smeIndividualApplication?: SmeIndividualApplication;
  createdAt: string;
};

export type SmeIndividualApplication = {
  id: string | null;
  personalBankName: string | null;
  personalBankAccountName: string | null;
  personalBankAccountNo: string | null;
  name: string | null;
  nric: string | null;
  icType: string | null;
  emailAddress: string | null;
  homeAddress1: string | null;
  homeAddress2: string | null;
  homeAddress3: string | null;
  homePostcode: string | null;
  homeCity: string | null;
  homeState: null;
  nricAddress1: string | null;
  nricAddress2: string | null;
  nricAddress3: string | null;
  nricPostcode: string | null;
  nricCity: string | null;
  nricState: string | null;
  applicantPhoneNo: string | null;
  applicantRace: string | null;
  applicantEmploymentType: string | null;
  emergencyContactPersonName: string | null;
  emergencyContactPersonPhoneNo: string | null;
  emergencyContactEmail: string | null;
  emergencyContactRelationship: string | null;
  createdAt: string;
  updatedAt: string;
};

export type SmeSolePropApplication = {
  id: string;
  smeApplicationId: string;
  smeApplication: SmeApplication;
  companyName: string;
  companyEmail: string;
  businessOldRegistrationNo: string;
  businessNewRegistrationNo: string;
  natureOfBusiness: string;
  businessContactNo: string;
  businessAddress1: string;
  businessAddress2: string;
  businessAddress3: string;
  businessPostcode: string;
  businessCity: string;
  businessState: string;
  personalBankName: string;
  personalBankAccountName: string;
  personalBankAccountNo: string;
  name: string;
  nric: string;
  icType: string;
  emailAddress: string;
  homeAddress1: string;
  homeAddress2: string;
  homeAddress3: string;
  homePostcode: string;
  homeCity: string;
  homeState: string;
  nricAddress1: string;
  nricAddress2: string;
  nricAddress3: string;
  nricPostcode: string;
  nricCity: string;
  nricState: string;
  applicantPhoneNo: string;
  applicantRace: string;
  applicantEmploymentType: string;
  emergencyContactPersonName: string;
  emergencyContactPersonPhoneNo: string;
  emergencyContactEmail: string;
  emergencyContactRelationship: string;
  createdAt: string;
  updatedAt: string;
};

export type SmePartnershipApplication = {
  id: string;
  smeApplicationId: string;
  smeApplication: SmeApplication;
  smePartnershipAgreementId: string;
  companyName: string;
  companyEmail: string;
  businessOldRegistrationNo: string;
  businessNewRegistrationNo: string;
  natureOfBusiness: string;
  businessContactNo: string;
  businessAuthorisedAddress1: string;
  businessAuthorisedAddress2: string;
  businessAuthorisedAddress3: string;
  businessAuthorisedPostcode: string;
  businessAuthorisedCity: string;
  businessAuthorisedState: string;
  personalBankName: string;
  personalBankAccountName: string;
  personalBankAccountNo: string;
  name: string;
  nric: string;
  icType: string;
  emailAddress: string;
  homeAddress1: string;
  homeAddress2: string;
  homeAddress3: string;
  homePostcode: string;
  homeCity: string;
  homeState: string;
  nricAddress1: string;
  nricAddress2: string;
  nricAddress3: string;
  nricPostcode: string;
  nricCity: string;
  nricState: string;
  applicantPhoneNo: string;
  applicantRace: string;
  applicantEmploymentType: string;
  emergencyContactPersonName: string;
  emergencyContactPersonPhoneNo: string;
  emergencyContactEmail: string;
  emergencyContactRelationship: string;
  smePartnershipDirectors: SmePartnershipDirector[];
};

export type SmeCompanyApplication = {
  id: string;
  smeApplicationId: string;
  smeApplication: SmeApplication;
  companyName: string;
  companyEmail: string;
  shareholdingStatus: string;
  businessOldRegistrationNo: string;
  businessNewRegistrationNo: string;
  natureOfBusiness: string;
  businessContactNo: string;
  businessAuthorisedAddress1: string;
  businessAuthorisedAddress2: string;
  businessAuthorisedAddress3: string;
  businessAuthorisedPostcode: string;
  businessAuthorisedCity: string;
  businessAuthorisedState: string;
  businessAddress1: string;
  businessAddress2: string;
  businessAddress3: string;
  businessPostcode: string;
  businessCity: string;
  businessState: string;
  registeredSsmAddress1: string;
  registeredSsmAddress2: string;
  registeredSsmAddress3: string;
  registeredSsmPostcode: string;
  registeredSsmCity: string;
  registeredSsmState: string;
  bankName: string;
  bankAccountName: string;
  bankAccountNo: string;
  smeCompanyDirectors: SmeCompanyDirector[];
};

export type SmePartner = {
  id: string;
  agentId: string;
  agentName: string;
  agentTypeCode: string;
  agentTypeName: string;
  address1: string;
  address2?: string;
  address3?: string;
  postCode: string;
  city: string;
  state: string;
  region: string;
  companyName?: string;
  companyROC?: string;
  companyAddress1?: string;
  companyAddress2?: string;
  companyAddress3?: string;
  companyPostCode?: string;
  companyCity?: string;
  companyState?: string;
  companyTel?: string;
  companyPIC?: string;
  companyPICDesignation?: string;
  bankName: string;
  bankAccNo: string;
  bankAccName: string;
  passportNo?: string;
  referenNoNew?: string;
  organizationType: string;
  ssmExpiryDate: string;
  tin?: string;
  activationDate: string;
};

export type SmeApplication = {
  id: string;
  createdAt: string;
  status: string;
  applicationNo: string;
  organizationType: ORGANIZATION_TYPE;
  redOneUsername: string;
  appliedLoanInterestRate: number;
  appliedLoanTenure: number;
  appliedLoanAmount: number;
  financeAmount: number;
  loanReason: string;
  caseFollowedUp: boolean;
  submissionDate?: string;
  processingFee: number;
  stampingFee: number;
  stampingDutyPercentage: number;
  smeIndividualApplicationId: string | null;
  smeIndividualApplication?: SmeIndividualApplication;
  smeSolePropApplication?: SmeSolePropApplication;
  smePartnershipApplication?: SmePartnershipApplication;
  smeCompanyApplication?: SmeCompanyApplication;
  smeAgreementId?: string;
  smeAgreement?: SmeAgreement;
  smePartner: SmePartner;
  promoCode?: string;
  processingFeeToDiscount?: number;
};

export type UploadedFile = {
  id: string;
  entityId: string;
  filename: string;
  originalName: string;
  mimeType: string;
  fileTypeId: string;
  fileType: {
    id: string;
    name: string;
    description?: string;
    fileSizeLimit?: number;
    createdAt?: string;
    updatedAt?: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type ApplicationStepStatus = {
  id: string;
  name: ApplicationStepStatusEnum;
  status: boolean;
  applicationId: string;
  createdAt: string;
  updatedAt: string;
};

export type RequiredFilesToUploadType = {
  code: string;
  value: string;
  textToBold: string;
  neededByIndividual: boolean;
  neededBySoleProp: boolean;
  neededByCompany: boolean;
  neededByPartnership: boolean;
};

export type ApplicationState = {
  currentApplicationStep: number;
  draftApplicationForm: ApplicationForm;
  partnerDetails: PartnerDetailsType;
  userFilledReferralCode?: boolean;
  cancelledApplication?: boolean;
  applicationStepStatus: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ApplicationStepStatus[];
  };
  // File Type Required to Upload by Organization Type
  requiredFilesToUpload: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: RequiredFilesToUploadType[];
  };
  // application page form data
  updateSmeApplicationDraftFormData: SmeApplication;
  applicationDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeApplication;
  };
  // uploaded document list in document upload page
  // & application summary page
  applicationFiles: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    unGroupData: UploadedFile[];
    data: Record<string, any[]>;
  };
};

// Phone number group interface
interface PhoneNumberGroup {
  countryCode?: string;
  phoneNo?: string;
}

// Special variant for applicant phone number
interface ApplicantPhoneNumberGroup {
  countryCode?: string;
  applicantPhoneNo?: string;
}

// Special variant for emergency contact phone number
interface EmergencyContactPhoneNumberGroup {
  countryCode?: string;
  emergencyContactPersonPhoneNo?: string;
}

// Special variant for business contact number
interface BusinessContactNumberGroup {
  countryCode?: string;
  businessContactNo?: string;
}

// Director interface
interface Director {
  directorName?: string;
  icType?: string;
  nric?: string;
  directorPhoneNoGroup?: PhoneNumberGroup;
  personalEmail?: string;
  noOfShares?: number;
  sharesPercentage?: number;
}

// Main application interface
export interface ApplicationForm {
  // Applicant Information
  name?: string;
  icType?: string;
  nric?: string;
  emailAddress?: string;
  applicantRace?: string;
  applicantEmploymentType?: string;
  personalBankName?: string;
  personalBankAccountName?: string;
  personalBankAccountNo?: string;
  dateOfBirth?: string;
  nationality?: string;
  occupationType?: string;
  employerName?: string;

  // Phone Numbers
  applicantPhoneNoGroup?: ApplicantPhoneNumberGroup;
  businessContactNoGroup?: BusinessContactNumberGroup;
  emergencyContactPersonPhoneNoGroup?: EmergencyContactPhoneNumberGroup;

  // Emergency Contact
  emergencyContactPersonName?: string;
  emergencyContactRelationshipId?: string;
  emergencyContactEmail?: string;

  // Business Information
  companyName?: string;
  companyEmail?: string;
  businessOldRegistrationNo?: string;
  businessNewRegistrationNo?: string;
  natureOfBusiness?: string;

  // Addresses
  homeAddress1?: string;
  homeAddress2?: string;
  homeAddress3?: string;
  homePostcode?: string;
  homeCity?: string;
  homeState?: string;

  nricAddress1?: string;
  nricAddress2?: string;
  nricAddress3?: string;
  nricPostcode?: string;
  nricCity?: string;
  nricState?: string;

  businessAddress1?: string;
  businessAddress2?: string;
  businessAddress3?: string;
  businessPostcode?: string;
  businessCity?: string;
  businessState?: string;

  businessAuthorisedAddress1?: string;
  businessAuthorisedAddress2?: string;
  businessAuthorisedAddress3?: string;
  businessAuthorisedPostcode?: string;
  businessAuthorisedCity?: string;
  businessAuthorisedState?: string;

  registeredSsmAddress1?: string;
  registeredSsmAddress2?: string;
  registeredSsmAddress3?: string;
  registeredSsmPostcode?: string;
  registeredSsmCity?: string;
  registeredSsmState?: string;

  mailingAddress1?: string;
  mailingAddress2?: string;
  mailingAddress3?: string;
  mailingPostcode?: string;
  mailingCity?: string;
  mailingState?: string;

  // Bank Information (Company)
  bankName?: string;
  bankAccountName?: string;
  bankAccountNo?: string;

  // Additional Information (Company)
  nameOfSeniorManagement?: string;
  countryOfIncorporation?: string;
  shareholdingStatus?: string;

  // Partnership Information
  applicantShareholdingType?: string;
  applicantSharesPercentage?: number;
  applicantNoOfShares?: number;

  // Director Information
  directors?: Director[];
}

const initialState: ApplicationState = {
  currentApplicationStep: 0,
  draftApplicationForm: {},
  partnerDetails: {} as PartnerDetailsType,
  requiredFilesToUpload: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  userFilledReferralCode: false,
  cancelledApplication: false,
  updateSmeApplicationDraftFormData: {} as SmeApplication,
  applicationDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {} as SmeApplication,
  },
  applicationStepStatus: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  applicationFiles: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    unGroupData: [],
    data: {},
  },
};

export const smeApplicationSlice = createSlice({
  name: "sme/application",
  initialState,
  reducers: {
    setDraftApplicationForm: (state, action) => {
      state.draftApplicationForm = {
        ...state.draftApplicationForm,
        ...action.payload,
      };
    },
    setSmeFinanceAmount: (state, action) => {
      state.applicationDetails.data.financeAmount = action.payload;
    },
    setPartnerDetails: (state, action) => {
      state.partnerDetails = action.payload;
    },
    setSmeApplicationDraft: (state, action) => {
      state.updateSmeApplicationDraftFormData = action.payload;
    },
    setSmeApplicationDetails: (
      state,
      action: PayloadAction<SmeApplication>,
    ) => {
      state.applicationDetails.data = action.payload;
    },
    setUserFilledReferralCodeState: (state, action: PayloadAction<boolean>) => {
      state.userFilledReferralCode = action.payload;
    },
    resetSmeApplicationState: (state, action) => {
      state.applicationDetails = initialState.applicationDetails;
      state.updateSmeApplicationDraftFormData =
        initialState.updateSmeApplicationDraftFormData;
      state.draftApplicationForm = initialState.draftApplicationForm;
    },
    setSmeApplicationStatusStep: (state, action) => {
      state.currentApplicationStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequiredFileByPartnerTypeThunk.pending, (state) => {
        state.requiredFilesToUpload.isLoading = true;
      })
      .addCase(getRequiredFileByPartnerTypeThunk.fulfilled, (state, action) => {
        state.requiredFilesToUpload.isLoading = false;
        state.requiredFilesToUpload.data = action.payload;
      })
      .addCase(getRequiredFileByPartnerTypeThunk.rejected, (state, action) => {
        state.requiredFilesToUpload.isLoading = false;
        state.requiredFilesToUpload.isError = true;
        state.requiredFilesToUpload.errorMessage = action.payload;
      });

    builder
      .addCase(getSmeApplicationDetailsThunk.pending, (state) => {
        state.applicationDetails.isLoading = true;
      })
      .addCase(getSmeApplicationDetailsThunk.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        state.applicationDetails.isLoading = false;
        state.applicationDetails.data = action.payload;
        state.updateSmeApplicationDraftFormData = action.payload;
      })
      .addCase(getSmeApplicationDetailsThunk.rejected, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.isError = true;
        state.applicationDetails.errorMessage = action.payload;
      });

    builder
      .addCase(createSmeIndividualApplication.pending, (state) => {
        state.applicationDetails.isLoading = true;
      })
      .addCase(createSmeIndividualApplication.fulfilled, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.data = action.payload;
        state.updateSmeApplicationDraftFormData = action.payload;
      })
      .addCase(createSmeIndividualApplication.rejected, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.isError = true;
        state.applicationDetails.errorMessage = action.payload;
      });

    builder
      .addCase(createSmePartnershipApplication.pending, (state) => {
        state.applicationDetails.isLoading = true;
      })
      .addCase(createSmePartnershipApplication.fulfilled, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.data = action.payload;
        state.updateSmeApplicationDraftFormData = action.payload;
      })
      .addCase(createSmePartnershipApplication.rejected, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.isError = true;
        state.applicationDetails.errorMessage = action.payload;
      });

    builder
      .addCase(createSmeSolePropApplication.pending, (state) => {
        state.applicationDetails.isLoading = true;
      })
      .addCase(createSmeSolePropApplication.fulfilled, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.data = action.payload;
        state.updateSmeApplicationDraftFormData = action.payload;
      })
      .addCase(createSmeSolePropApplication.rejected, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.isError = true;
        state.applicationDetails.errorMessage = action.payload;
      });

    builder
      .addCase(createSmeCompanyApplication.pending, (state) => {
        state.applicationDetails.isLoading = true;
      })
      .addCase(createSmeCompanyApplication.fulfilled, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.data = action.payload;
        state.updateSmeApplicationDraftFormData = action.payload;
      })
      .addCase(createSmeCompanyApplication.rejected, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.isError = true;
        state.applicationDetails.errorMessage = action.payload;
      });

    builder
      .addCase(createApplicationDraftThunk.pending, (state) => {
        state.applicationDetails.isLoading = true;
      })
      .addCase(createApplicationDraftThunk.fulfilled, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.data = action.payload;
      })
      .addCase(createApplicationDraftThunk.rejected, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.isError = true;
        state.applicationDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getApplicationStepStatusThunk.pending, (state) => {
        state.applicationStepStatus.isLoading = true;
      })
      .addCase(getApplicationStepStatusThunk.fulfilled, (state, action) => {
        state.applicationStepStatus.isLoading = false;
        state.applicationStepStatus.data = action.payload;
      })
      .addCase(getApplicationStepStatusThunk.rejected, (state, action) => {
        state.applicationStepStatus.isLoading = false;
        state.applicationStepStatus.isError = true;
        state.applicationStepStatus.errorMessage = action.payload;
      });

    builder
      .addCase(getFilesByEntityIdThunk.pending, (state) => {
        state.applicationFiles.isLoading = true;
      })
      .addCase(getFilesByEntityIdThunk.fulfilled, (state, action) => {
        state.applicationFiles.isLoading = false;
        state.applicationFiles.unGroupData = action.payload;
        state.applicationFiles.data = groupBy(
          action.payload,
          (file: UploadedFile) => file.fileType.name.toLowerCase(),
        );
      })
      .addCase(getFilesByEntityIdThunk.rejected, (state, action) => {
        state.applicationFiles.isLoading = false;
        state.applicationFiles.isError = true;
        state.applicationFiles.errorMessage = action.payload;
      });

    // Patch Application Status to Pending if API call is successful
    builder.addCase(
      patchSmeApplicationToPendingThunk.fulfilled,
      (state, action) => {
        state.applicationDetails.data.status = "PENDING";
      },
    );
  },
});

export const {
  setSmeApplicationDetails,
  setSmeApplicationDraft,
  setPartnerDetails,
  setSmeFinanceAmount,
  resetSmeApplicationState,
  setDraftApplicationForm,
  setSmeApplicationStatusStep,
} = smeApplicationSlice.actions;

export default smeApplicationSlice.reducer;
