import { createSlice } from "@reduxjs/toolkit";
import { getSmeProductByReferralCodeThunk } from "../../services/smeProductService/smeProductThunk";
import { setSmeApplicationDraft } from "../smeApplication/smeApplicationSlice";
import { roundDownToNearestThousand } from "../../utils/number";

export type SmeProduct = {
  id: string;
  code: string;
  minActiveLine: number;
  maxActiveLine: number;
  minAvgRecurringAmount: number;
  maxAvgRecurringAmount: number;
  interestRate: number;
  minTenure: number;
  maxTenure: number;
  intervalTenure: number;
  minLoanAmount: number;
  maxLoanAmount: number;
  intervalLoanAmount: number;
  processingFee: number;
  stampingDutyPercentage: number;
  referralCode: string;
  processingFeeToDiscount: number;
  createdAt: string;
  updatedAt: string;
};
export type EligibleSmeProduct = {
  loanAmountToMultiply: number;
  product: SmeProduct;
  messageToShow: string[];
};

export type SmeProductState = {
  eligibleSmeProduct: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: EligibleSmeProduct;
  };
  eligibleMaxLoanAmount: number;
  selectedLoanTenure: number;
};

const initialState: SmeProductState = {
  eligibleSmeProduct: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      messageToShow: [],
      loanAmountToMultiply: 0,
      product: {
        interestRate: 0,
        minTenure: 0,
        maxTenure: 0,
        intervalTenure: 0,
        minLoanAmount: 0,
        maxLoanAmount: 0,
        intervalLoanAmount: 0,
        processingFee: 0,
        stampingDutyPercentage: 0,
        processingFeeToDiscount: 0,
      } as SmeProduct,
    },
  },
  eligibleMaxLoanAmount: 0,
  selectedLoanTenure: 0,
};

export const smeProductSlice = createSlice({
  name: "sme/product",
  initialState,
  reducers: {
    resetSmeApplicationState: () => initialState,
    setSmeProductSelectedLoanTenure: (state, action) => {
      state.selectedLoanTenure = action.payload;
      state.eligibleMaxLoanAmount =
        roundDownToNearestThousand(+state.eligibleSmeProduct.data.loanAmountToMultiply * action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSmeProductByReferralCodeThunk.pending, (state) => {
        state.eligibleSmeProduct.isLoading = true;
      })
      .addCase(getSmeProductByReferralCodeThunk.fulfilled, (state, action) => {
        state.eligibleSmeProduct.isLoading = false;
        state.eligibleSmeProduct.data = action.payload;

        state.eligibleMaxLoanAmount =
          roundDownToNearestThousand(+action.payload.loanAmountToMultiply *
            +action.payload.product?.maxTenure || 0);
      })
      .addCase(getSmeProductByReferralCodeThunk.rejected, (state, action) => {
        state.eligibleSmeProduct.isLoading = false;
        state.eligibleSmeProduct.isError = true;
        state.eligibleSmeProduct.errorMessage = action.payload;
      });
  },
});
export const { resetSmeApplicationState, setSmeProductSelectedLoanTenure } =
  smeProductSlice.actions;
export default smeProductSlice.reducer;
