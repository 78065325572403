export const roundUpToNearestThousands = (number: number) => {
  if (number % 1000 === 0) return number;
  return parseInt(String(number / 1000)) * 1000 + 1000;
};

export const roundDownToNearestThousand = (num: number): number => {
  return Math.floor(num / 1000) * 1000;
};

export const toOrdinal = (n: number) => {
  const absoluteN = Math.abs(n);
  const lastDigit = absoluteN % 10;
  const lastTwoDigits = absoluteN % 100;

  // Special case for 11, 12, 13
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return `${n}th`;
  }

  // Handle other cases
  switch (lastDigit) {
    case 1:
      return `${n}st`;
    case 2:
      return `${n}nd`;
    case 3:
      return `${n}rd`;
    default:
      return `${n}th`;
  }
};
