import { masterSetupAxiosInstance } from "../../axios/masterSetupAxios";

const getRaceList = async () => {
  const url = `/redCash/master/api/v1.0/race/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

const getGrossSalaryRangeList = async () => {
  const url = `/redCash/master/api/v1.0/grossSalaryRange/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

const getLoanReasonList = async () => {
  const url = `/redCash/master/api/v1.0/loanReason/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

const getEmployeeTypeList = async () => {
  const url = `/redCash/master/api/v1.0/employeeType/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

const getBankList = async () => {
  const url = `/redCash/master/api/v1.0/bank/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

const getRelationshipList = async () => {
  const url = `/redCash/master/api/v1.0/relationship/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

const getSmeLoanReasonList = async () => {
  const url = `/redCash/master/api/v1.0/smeLoanReason`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

const dropdownParameterService = {
  getRaceList,
  getGrossSalaryRangeList,
  getLoanReasonList,
  getEmployeeTypeList,
  getBankList,
  getRelationshipList,
  getSmeLoanReasonList,
};

export default dropdownParameterService;
