import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getCurrentUserThunk,
  getLatestUserDetailsThunk,
} from "../../services/userService/userThunk";
import { getNricInfo } from "../../utils/nric";
import { getLoanInsuranceForUserThunk } from "../../services/loanInsuranceService/loanInsuranceThunk";

export type LoginPayload = {
  callerId: string;
  password: string;
};

export type RedCashLoginPayload = {
  email?: string;
  password?: string;
};

export type ResetPasswordPayload = {
  callerId: string;
};

export type ChangePasswordPayload = {
  oldPassword: string;
  newPassword: string;
};

export type CreateRedCashAccountRequest = {
  password?: string;
  id?: string;
};

export type CreateRedCashSmeAccountRequest = {
  password: string;
  smePartnerId: string;
  email: string;
};

export type UserLoanInsuranceDetails = {
  id: string;
  tenure: number;
  amount: number;
  tax: number;
  taxAmount: number;
  amountWithTax: number;
  loanInsuranceProductId: string;
};

export type User = {
  id?: string;
  firstName?: string;
  lastName?: string;
  isNric?: boolean;
  identityCardType?: string;
  nric?: string;
  password?: string;
  age?: number;
  phoneNo?: string;
  residentialAddress1?: string;
  residentialAddress2?: string;
  residentialAddress3?: string;
  city?: string;
  postcode?: string | number;
  state?: string;
  grossSalaryRangeId?: string;
  raceId?: string;
  email?: string;
  agreeTncAndPp?: boolean;
  agreeMarketing?: boolean;
  allowToApplyNewLoanAfterDateTime?: string;
  partnerId?: string;
  language?: string;
  createdAt?: string;
  updatedAt?: string;
  currentlyEditing?: boolean;
  redCashPasswordHash?: string;
  redCashPasswordSalt?: string;
  icType?: string;
  tinNumber?: string;
};

export type UserState = {
  hasLoggedUser: boolean;
  registrationFormData: User;
  currentUser: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: User;
  };
  latestUserDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: User;
  };
  userLoanInsuranceDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: UserLoanInsuranceDetails;
  };
};

const initialState: UserState = {
  hasLoggedUser: false,
  registrationFormData: {
    firstName: undefined,
    lastName: undefined,
    isNric: true,
    nric: "",
    phoneNo: undefined,
    residentialAddress1: undefined,
    residentialAddress2: "",
    residentialAddress3: "",
    city: undefined,
    postcode: "",
    state: undefined,
    raceId: undefined,
    email: undefined,
    currentlyEditing: false,
    icType: "",
  },
  currentUser: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
  latestUserDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
  userLoanInsuranceDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetCurrentUser: (state) => {
      state.currentUser.data = undefined;
    },
    resetAllUserData: () => ({
      ...initialState,
      hasLoggedUser: true,
    }),
    resetAllUserDataForReapplyFromCancelled: (state) => ({
      ...initialState,
      hasLoggedUser: true,
      registrationFormData: {
        ...initialState.registrationFormData,
        phoneNo: state.registrationFormData.phoneNo,
      },
    }),
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.currentUser.data = action.payload;
    },
    setCurrentUserFullName: (state, action: PayloadAction<string>) => {
      state.currentUser.data = {
        ...state.currentUser.data,
        firstName: action.payload,
      };
    },
    setRegistrationFormDataLogin: (state, action: PayloadAction<User>) => {
      const res: any = action.payload;
      const registrationFormDataValues = {
        ...res,
        fullName: res?.firstName,
        nric1: res?.nric?.slice(0, 6),
        nric2: res?.nric?.slice(6, 8),
        nric3: res?.nric?.slice(8, 12),
        age: getNricInfo(res?.nric).age,
        address: {
          residentialAddress1: res?.residentialAddress1,
          residentialAddress2: res?.residentialAddress2,
          residentialAddress3: res?.residentialAddress3,
        },
      };
      state.registrationFormData = registrationFormDataValues;
    },
    setRegistrationFormData: (state, action: PayloadAction<User>) => {
      state.registrationFormData = action.payload;
    },
    setRegistrationFormDataEmail: (state, action: PayloadAction<string>) => {
      state.registrationFormData.email = action.payload;
    },
    setCurrentUserEmail: (state, action: PayloadAction<string>) => {
      if (state.currentUser) {
        state.currentUser.data = {
          ...state.currentUser.data,
          email: action.payload,
        };
      }
    },
    setHasLoggedUser: (state, action: PayloadAction<boolean>) => {
      state.hasLoggedUser = action.payload;
    },
    userStateReset: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUserThunk.pending, (state) => {
        state.currentUser.isLoading = true;
      })
      .addCase(
        getCurrentUserThunk.fulfilled,
        (state, action: PayloadAction<{ exist: boolean; user: User }>) => {
          state.currentUser.isLoading = false;
          state.currentUser.data = action.payload.user;
        },
      )
      .addCase(getCurrentUserThunk.rejected, (state, action) => {
        state.currentUser.isLoading = false;
        state.currentUser.isError = true;
        state.currentUser.errorMessage = action.payload;
      });

    builder
      .addCase(getLatestUserDetailsThunk.pending, (state) => {
        state.latestUserDetails.isLoading = true;
      })
      .addCase(getLatestUserDetailsThunk.fulfilled, (state, action) => {
        state.latestUserDetails.isLoading = false;
        state.latestUserDetails.data = action.payload;
      })
      .addCase(getLatestUserDetailsThunk.rejected, (state, action) => {
        state.latestUserDetails.isLoading = false;
        state.latestUserDetails.isError = true;
        state.latestUserDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getLoanInsuranceForUserThunk.pending, (state) => {
        state.userLoanInsuranceDetails.isLoading = true;
      })
      .addCase(getLoanInsuranceForUserThunk.fulfilled, (state, action) => {
        state.userLoanInsuranceDetails.isLoading = false;
        state.userLoanInsuranceDetails.data = action.payload.data;
      })
      .addCase(getLoanInsuranceForUserThunk.rejected, (state, action) => {
        state.userLoanInsuranceDetails.isLoading = false;
        state.userLoanInsuranceDetails.isError = true;
        state.userLoanInsuranceDetails.errorMessage = action.payload;
      });
  },
});

export const {
  resetCurrentUser,
  resetAllUserData,
  resetAllUserDataForReapplyFromCancelled,
  setCurrentUser,
  setRegistrationFormData,
  setRegistrationFormDataLogin,
  userStateReset,
  setRegistrationFormDataEmail,
  setCurrentUserEmail,
  setHasLoggedUser,
  setCurrentUserFullName,
} = userSlice.actions;

export default userSlice.reducer;
