import { createAsyncThunk } from "@reduxjs/toolkit";
import smeApplicationService from "./smeApplicationService";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import { SmeSolePropApplication } from "../../features/smeApplication/smeApplicationSlice";

export const getSmeApplicationDetailsThunk = createAsyncThunk(
  "sme/application/getDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.getSmeApplicationDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApplicationStepStatusThunk = createAsyncThunk(
  "application/getStepStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.getApplicationStepStatus(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const testEligibilityThunk = createAsyncThunk(
  "application/testEligibility",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.testEligibility(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createSmeApplicationDraftThunk = createAsyncThunk(
  "/sme/application/createDraft",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.createSmeApplicationDraft(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchSmeApplicationToPendingThunk = createAsyncThunk(
  "/sme/application/pending",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchSmeApplicationToPending(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateApplicationThunk = createAsyncThunk(
  "application/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.updateApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteApplicationThunk = createAsyncThunk(
  "application/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.deleteApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const cancelApplicationThunk = createAsyncThunk(
  "application/cancel",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.cancelApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchApplicationFormCompletedStepThunk = createAsyncThunk(
  "application/application-form-completed",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchApplicationFormCompletedStep(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchEkycCompletedStepThunk = createAsyncThunk(
  "application/ekyc-completed",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchEkycCompletedStep(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchEmailVerificationCompletedStepThunk = createAsyncThunk(
  "application/email-verification-completed",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchEmailVerificationCompletedStep(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchDocumentUploadCompletedStepThunk = createAsyncThunk(
  "application/document-upload-completed",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchDocumentUploadCompletedStep(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchApplicationStepErrorThunk = createAsyncThunk(
  "application/application-step-error",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchApplicationStepError(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getLatestApplicationStatusThunk = createAsyncThunk(
  "application/getLatestStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.getLatestApplicationStatus(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createSmeIndividualApplication = createAsyncThunk(
  "sme/application/individual",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.createSmeIndividualApplication(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createSmeSolePropApplication = createAsyncThunk(
  "sme/application/soleProp",
  async (requestData: SmeSolePropApplication, thunkAPI) => {
    try {
      return await smeApplicationService.createSmeSolePropApplication(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createSmePartnershipApplication = createAsyncThunk(
  "sme/application/partnership",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.createSmePartnershipApplication(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createSmeCompanyApplication = createAsyncThunk(
  "sme/application/company",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.createSmeCompanyApplication(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteSmeApplicationThunk = createAsyncThunk(
  "sme/deleteApplication",
  async (id: string, thunkAPI) => {
    try {
      return await smeApplicationService.deleteSmeApplication(id);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const cancelSmeApplicationThunk = createAsyncThunk(
  "sme/cancelApplication",
  async (id: string, thunkAPI) => {
    try {
      return await smeApplicationService.cancelSmeApplication(id);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
