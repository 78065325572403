import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { getNricInfo } from "../../utils/nric";
import moment from "moment";

const getSmeApplicationDetails = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/smeApplication/${applicationId}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getApplicationStepStatus = async (requestData: any) => {
  const { applicationId } = requestData;

  const url = `/redCash/api/v1.0/application/${applicationId}/status`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const testEligibility = async (requestData: any) => {
  const url = `/redCash/api/v1.0/redone/validateNric`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const createSmeApplicationDraft = async (requestData: any) => {
  const url = `/redCash/api/v1.0/smeApplication/draft`;

  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const patchSmeApplicationToPending = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/smeApplication/${applicationId}/pending`;

  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const updateApplication = async (requestData: any) => {
  const { applicationId, payload, language } = requestData;
  const url = `/redCash/api/v1.0/application/${language}/${applicationId}`;

  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const deleteApplication = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/application/${applicationId}`;

  const res = await beSetupAxiosInstance.delete(url);
  return res.data;
};

const cancelApplication = async (requestData: any) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}/status/cancel`;

  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const patchApplicationFormCompletedStep = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/application-status-step/${applicationId}/application-form-completed`;

  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const patchEkycCompletedStep = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/application-status-step/${applicationId}/ekyc-completed`;

  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const patchEmailVerificationCompletedStep = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/application-status-step/${applicationId}/email-varification-completed`;

  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const patchDocumentUploadCompletedStep = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/application-status-step/${applicationId}/document-upload-completed`;

  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const patchApplicationStepError = async (requestData: any) => {
  const { applicationId, stepName } = requestData;
  const url = `/redCash/api/v1.0/application-status-step/${applicationId}/${stepName}/error`;

  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const getLatestApplicationStatus = async (requestData: any) => {
  const { userId } = requestData;
  const url = `/redCash/api/v1.0/application/userId/${userId}`;
  const res = await beSetupAxiosInstance.get(url);

  return res.data;
};

const createSmeIndividualApplication = async (requestData: any) => {
  const body = {
    smeApplicationId: requestData.smeApplicationId,
    name: requestData.name,
    nric: requestData.nric,
    icType: requestData.icType,
    emailAddress: requestData.emailAddress,
    homeAddress1: requestData.homeAddress1,
    homeAddress2: requestData.homeAddress2,
    homeAddress3: requestData.homeAddress3,
    homePostcode: requestData.homePostcode,
    homeCity: requestData.homeCity,
    homeState: requestData.homeState,
    nricAddress1: requestData.nricAddress1,
    nricAddress2: requestData.nricAddress2,
    nricAddress3: requestData.nricAddress3,
    nricPostcode: requestData.nricPostcode,
    nricCity: requestData.nricCity,
    nricState: requestData.nricState,
    applicantPhoneNo: requestData.applicantPhoneNo,
    applicantRace: requestData.applicantRace,
    applicantEmploymentType: requestData.applicantEmploymentType,
    emergencyContactPersonName: requestData.emergencyContactPersonName,
    emergencyContactPersonPhoneNo: requestData.emergencyContactPersonPhoneNo,
    emergencyContactEmail: requestData.emergencyContactEmail,
    emergencyContactRelationship: requestData.emergencyContactRelationshipId,
    dateOfBirth: moment(getNricInfo(requestData.nric)?.birthDate).toISOString(),
    nationality: requestData.nationality,
    occupationType: requestData.occupationType,
    employerName: requestData.employerName,
    mailingAddress1: requestData.mailingAddress1,
    mailingAddress2: requestData.mailingAddress2,
    mailingAddress3: requestData.mailingAddress3,
    mailingPostcode: requestData.mailingPostcode,
    mailingCity: requestData.mailingCity,
    mailingState: requestData.mailingState,
    personalBankName: requestData.personalBankName,
    personalBankAccountName: requestData.personalBankAccountName,
    personalBankAccountNo: requestData.personalBankAccountNo,
  };

  const url = `/redCash/api/v1.0/smeApplication/smeIndividualApplication`;
  const res = await beSetupAxiosInstance.post(url, body);

  return res.data;
};

const createSmeSolePropApplication = async (requestData: any) => {
  const body = {
    smeApplicationId: requestData.smeApplicationId,
    companyName: requestData.companyName,
    companyEmail: requestData.companyEmail,
    businessOldRegistrationNo: requestData.businessOldRegistrationNo,
    businessNewRegistrationNo: requestData.businessNewRegistrationNo,
    natureOfBusiness: requestData.natureOfBusiness,
    businessContactNo: requestData.businessContactNo,
    businessAddress1: requestData.businessAddress1,
    businessAddress2: requestData.businessAddress2,
    businessAddress3: requestData.businessAddress3,
    businessPostcode: requestData.businessPostcode,
    businessCity: requestData.businessCity,
    businessState: requestData.businessState,
    registeredSsmAddress1: requestData.registeredSsmAddress1,
    registeredSsmAddress2: requestData.registeredSsmAddress2,
    registeredSsmAddress3: requestData.registeredSsmAddress3,
    registeredSsmPostcode: requestData.registeredSsmPostcode,
    registeredSsmCity: requestData.registeredSsmCity,
    registeredSsmState: requestData.registeredSsmState,
    name: requestData.name,
    nric: requestData.nric,
    icType: requestData.icType,
    emailAddress: requestData.emailAddress,
    homeAddress1: requestData.homeAddress1,
    homeAddress2: requestData.homeAddress2,
    homeAddress3: requestData.homeAddress3,
    homePostcode: requestData.homePostcode,
    homeCity: requestData.homeCity,
    homeState: requestData.homeState,
    nricAddress1: requestData.nricAddress1,
    nricAddress2: requestData.nricAddress2,
    nricAddress3: requestData.nricAddress3,
    nricPostcode: requestData.nricPostcode,
    nricCity: requestData.nricCity,
    nricState: requestData.nricState,
    applicantPhoneNo: requestData.applicantPhoneNo,
    applicantRace: requestData.applicantRace,
    applicantEmploymentType: requestData.applicantEmploymentType,
    emergencyContactPersonName: requestData.emergencyContactPersonName,
    emergencyContactPersonPhoneNo: requestData.emergencyContactPersonPhoneNo,
    emergencyContactEmail: requestData.emergencyContactEmail,
    emergencyContactRelationship: requestData.emergencyContactRelationshipId,
    dateOfBirth: moment(getNricInfo(requestData.nric)?.birthDate).toISOString(),
    nationality: requestData.nationality,
    occupationType: requestData.occupationType,
    employerName: requestData.employerName,
    mailingAddress1: requestData.mailingAddress1,
    mailingAddress2: requestData.mailingAddress2,
    mailingAddress3: requestData.mailingAddress3,
    mailingPostcode: requestData.mailingPostcode,
    mailingCity: requestData.mailingCity,
    mailingState: requestData.mailingState,
    personalBankName: requestData.personalBankName,
    personalBankAccountName: requestData.personalBankAccountName,
    personalBankAccountNo: requestData.personalBankAccountNo,
    countryOfIncorporation: requestData.countryOfIncorporation,
    nameOfSeniorManagement: requestData.nameOfSeniorManagement,
  };

  const url = `/redCash/api/v1.0/smeApplication/smeSolePropApplication`;
  const res = await beSetupAxiosInstance.post(url, body);

  return res.data;
};

const createSmePartnershipApplication = async (requestData: any) => {
  const body = {
    smeApplicationId: requestData.smeApplicationId,
    companyName: requestData.companyName,
    companyEmail: requestData.companyEmail,
    businessOldRegistrationNo: requestData.businessOldRegistrationNo,
    businessNewRegistrationNo: requestData.businessNewRegistrationNo,
    natureOfBusiness: requestData.natureOfBusiness,
    businessContactNo: requestData.businessContactNo,
    businessAddress1: requestData.businessAddress1,
    businessAddress2: requestData.businessAddress2,
    businessAddress3: requestData.businessAddress3,
    businessPostcode: requestData.businessPostcode,
    businessCity: requestData.businessCity,
    businessState: requestData.businessState,
    businessAuthorisedAddress1: requestData.businessAuthorisedAddress1,
    businessAuthorisedAddress2: requestData.businessAuthorisedAddress2,
    businessAuthorisedAddress3: requestData.businessAuthorisedAddress3,
    businessAuthorisedPostcode: requestData.businessAuthorisedPostcode,
    businessAuthorisedCity: requestData.businessAuthorisedCity,
    businessAuthorisedState: requestData.businessAuthorisedState,
    registeredSsmAddress1: requestData.registeredSsmAddress1,
    registeredSsmAddress2: requestData.registeredSsmAddress2,
    registeredSsmAddress3: requestData.registeredSsmAddress3,
    registeredSsmPostcode: requestData.registeredSsmPostcode,
    registeredSsmCity: requestData.registeredSsmCity,
    registeredSsmState: requestData.registeredSsmState,
    name: requestData.name,
    nric: requestData.nric,
    icType: requestData.icType,
    emailAddress: requestData.emailAddress,
    homeAddress1: requestData.homeAddress1,
    homeAddress2: requestData.homeAddress2,
    homeAddress3: requestData.homeAddress3,
    homePostcode: requestData.homePostcode,
    homeCity: requestData.homeCity,
    homeState: requestData.homeState,
    nricAddress1: requestData.nricAddress1,
    nricAddress2: requestData.nricAddress2,
    nricAddress3: requestData.nricAddress3,
    nricPostcode: requestData.nricPostcode,
    nricCity: requestData.nricCity,
    nricState: requestData.nricState,
    applicantPhoneNo: requestData.applicantPhoneNo,
    applicantRace: requestData.applicantRace,
    applicantEmploymentType: requestData.applicantEmploymentType,
    emergencyContactPersonName: requestData.emergencyContactPersonName,
    emergencyContactPersonPhoneNo: requestData.emergencyContactPersonPhoneNo,
    emergencyContactEmail: requestData.emergencyContactEmail,
    emergencyContactRelationship: requestData.emergencyContactRelationshipId,
    dateOfBirth: moment(getNricInfo(requestData.nric)?.birthDate).toISOString(),
    nationality: requestData.nationality,
    occupationType: requestData.occupationType,
    employerName: requestData.employerName,
    mailingAddress1: requestData.mailingAddress1,
    mailingAddress2: requestData.mailingAddress2,
    mailingAddress3: requestData.mailingAddress3,
    mailingPostcode: requestData.mailingPostcode,
    mailingCity: requestData.mailingCity,
    mailingState: requestData.mailingState,
    personalBankName: requestData.personalBankName,
    personalBankAccountName: requestData.personalBankAccountName,
    personalBankAccountNo: requestData.personalBankAccountNo,
    countryOfIncorporation: requestData.countryOfIncorporation,
    nameOfSeniorManagement: requestData.nameOfSeniorManagement,
    directors: requestData.directors,
  };

  const url = `/redCash/api/v1.0/smeApplication/smePartnershipApplication`;
  const res = await beSetupAxiosInstance.post(url, body);

  return res.data;
};

const createSmeCompanyApplication = async (requestData: any) => {
  const body = {
    smeApplicationId: requestData.smeApplicationId,
    companyName: requestData.companyName,
    companyEmail: requestData.companyEmail,
    shareholdingStatus: requestData.shareholdingStatus,
    businessOldRegistrationNo: requestData.businessOldRegistrationNo,
    businessNewRegistrationNo: requestData.businessNewRegistrationNo,
    natureOfBusiness: requestData.natureOfBusiness,
    businessContactNo: requestData.businessContactNo,
    businessAddress1: requestData.businessAddress1,
    businessAddress2: requestData.businessAddress2,
    businessAddress3: requestData.businessAddress3,
    businessPostcode: requestData.businessPostcode,
    businessCity: requestData.businessCity,
    businessState: requestData.businessState,
    businessAuthorisedAddress1: requestData.businessAuthorisedAddress1,
    businessAuthorisedAddress2: requestData.businessAuthorisedAddress2,
    businessAuthorisedAddress3: requestData.businessAuthorisedAddress3,
    businessAuthorisedPostcode: requestData.businessAuthorisedPostcode,
    businessAuthorisedCity: requestData.businessAuthorisedCity,
    businessAuthorisedState: requestData.businessAuthorisedState,
    registeredSsmAddress1: requestData.registeredSsmAddress1,
    registeredSsmAddress2: requestData.registeredSsmAddress2,
    registeredSsmAddress3: requestData.registeredSsmAddress3,
    registeredSsmPostcode: requestData.registeredSsmPostcode,
    registeredSsmCity: requestData.registeredSsmCity,
    registeredSsmState: requestData.registeredSsmState,
    bankName: requestData.bankName,
    bankAccountName: requestData.bankAccountName,
    bankAccountNo: requestData.bankAccountNo,
    countryOfIncorporation: requestData.countryOfIncorporation,
    nameOfSeniorManagement: requestData.nameOfSeniorManagement,
    directors: requestData.directors,
  };

  const url = `/redCash/api/v1.0/smeApplication/smeCompanyApplication`;
  const res = await beSetupAxiosInstance.post(url, body);

  return res.data;
};

const cancelSmeApplication = async (id: string) => {
  const url = `/redCash/api/v1.0/smeApplication/${id}/status/cancel`;
  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const deleteSmeApplication = async (id: string) => {
  const url = `/redCash/api/v1.0/smeApplication/${id}`;
  const res = await beSetupAxiosInstance.delete(url);
  return res.data;
};

const smeApplicationService = {
  patchApplicationFormCompletedStep,
  patchEkycCompletedStep,
  patchEmailVerificationCompletedStep,
  patchDocumentUploadCompletedStep,
  patchApplicationStepError,
  getSmeApplicationDetails,
  getApplicationStepStatus,
  createSmeApplicationDraft,
  updateApplication,
  deleteApplication,
  cancelApplication,
  testEligibility,
  getLatestApplicationStatus,
  patchSmeApplicationToPending,
  createSmeIndividualApplication,
  createSmeSolePropApplication,
  createSmePartnershipApplication,
  createSmeCompanyApplication,
  cancelSmeApplication,
  deleteSmeApplication,
};

export default smeApplicationService;
