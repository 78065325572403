import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import fileService from "../fileService/fileService";
import preRecordedVideoService from "./preRecordedVideoService";

export const getPreRecordedVideoThunk = createAsyncThunk(
  "preRecordedVideo/getList",
  async (requestData, thunkAPI) => {
    try {
      return await preRecordedVideoService.getPreRecordedVideo();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPreRecordedVideoBmThunk = createAsyncThunk(
  "preRecordedVideo/getListBm",
  async (requestData, thunkAPI) => {
    try {
      return await preRecordedVideoService.getPreRecordedVideoBm();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPreRecordedVideoFilesThunk = createAsyncThunk(
  "preRecordedVideo/getPreRecordedVideoFiles",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getMasterSetupFilesByEntityId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmePreRecordedVideoThunk = createAsyncThunk(
  "preRecordedVideo/getSmePreRecordedVideoFile",
  async (requestData: any, thunkAPI) => {
    try {
      return await preRecordedVideoService.getSmePreRecordedVideo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
