import { beSetupAxiosInstance } from "../../axios/beSetupAxios";

const getSmeProductByReferralCode = async (requestData: any) => {
  const url = `/redCash/api/v1.0/sme/productProfile`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const smeProductService = {
  getSmeProductByReferralCode,
};

export default smeProductService;
