import { authSetupAxiosInstance } from "../../axios/authSetupAxios";
import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import {
  ChangePasswordPayload,
  CreateRedCashAccountRequest,
  LoginPayload,
  RedCashLoginPayload,
  User,
} from "../../features/user/userSlice";

const checkValidPhoneNo = async (requestData: LoginPayload) => {
  const url = `/redCash/api/v1.0/redone/firstLogin`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const login = async (requestData: LoginPayload) => {
  const url = `/redCash/api/v1.0/redone/login`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const redCashLogin = async (requestData: RedCashLoginPayload) => {
  const url = `/redCash/api/v1.0/redcash/login`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const getCurrentUser = async (requestData: any) => {
  const { userId } = requestData;
  const url = `/redCash/auth/api/v1.0/user/${userId}`;
  const res = await authSetupAxiosInstance.get(url);
  return res.data;
};

const getUserDetailsByAccessToken = async () => {
  const url = `/redCash/auth/api/v1.0/user/me`;
  const res = await authSetupAxiosInstance.get(url);
  return res.data;
};

const createUser = async (requestData: User) => {
  const url = `/redCash/auth/api/v1.0/user/${requestData.language}`;

  const res = await authSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const createRedCashAccount = async (
  requestData: CreateRedCashAccountRequest,
) => {
  const url = `/redCash/auth/api/v1.0/redcash/user`;

  const res = await authSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const updateUser = async (requestData: any) => {
  const { userId, payload, applicationId, agreementId } = requestData;
  const url = `/redCash/admin/auth/api/v1.0/user/${userId}/applicationId/${applicationId}/agreementId/${agreementId}`;

  const res = await authSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const updateUserAuthOnly = async (requestData: any) => {
  const { userId, payload } = requestData;
  const url = `/redCash/admin/auth/api/v1.0/user/updateAuthOnly/${userId}`;

  const res = await authSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const updateUserEmail = async (requestData: any) => {
  const { id, email } = requestData;
  const url = `/redCash/auth/api/v1.0/user/${id}/email`;

  const res = await authSetupAxiosInstance.patch(url, {
    email,
  });
  return res.data;
};

const resetPassword = async (requestData: any) => {
  const { callerId } = requestData;
  const url = `/redCash/api/v1.0/redone/resetPassword`;

  const res = await beSetupAxiosInstance.post(url, {
    callerId,
  });
  return res.data;
};

const changePassword = async (payload: ChangePasswordPayload) => {
  const url = `/redCash/auth/api/v1.0/user/update-password`;

  const res = await authSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const getTrustGateUserCertInfo = async (requestData: any) => {
  const { nric } = requestData;
  const url = `/redCash/api/v1.0/trustGate/cert/${nric}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const enrolTrustGateUserCertInfo = async (requestData: any) => {
  const { nric } = requestData;
  const url = `/redCash/api/v1.0/trustGate/enrol/${nric}`;
  const res = await beSetupAxiosInstance.post(url);
  return res.data;
};

const deleteUser = async (requestData: any) => {
  const { userId, payload } = requestData;
  const url = `/redCash/auth/api/v1.0/user/${userId}`;

  const res = await authSetupAxiosInstance.delete(url, payload);
  return res.data;
};

const requestToResetEmail = async (requestData: any) => {
  const url = `/redCash/auth/api/v1.0/request-reset-password/email`;
  const res = await authSetupAxiosInstance.post(url, requestData);

  return res.data;
};

const checkResetPasswordToken = async (requestData: any) => {
  const url = `/redCash/auth/api/v1.0/check-reset-password-token/token`;
  const res = await authSetupAxiosInstance.post(url, requestData);

  return res.data;
};

const updateUserPassword = async (requestData: any, token: string) => {
  //ToDo: add the token to the header
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `/redCash/auth/api/v1.0/user/reset-password`;

  const res = await authSetupAxiosInstance.patch(url, requestData, config);
  return res.data;
};

const patchUserApplicationCreated = async (requestData: any) => {
  const { userId, payload } = requestData;
  const url = `/redCash/auth/api/v1.0/user/application-created/${userId}`;

  const res = await authSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const checkUserTinNo = async (requestData: any) => {
  const { userId, tinNumber } = requestData;

  const url = `/redCash/auth/api/v1.0/user/${userId}/tinNumber`;

  const res = await authSetupAxiosInstance.patch(url, {
    tinNumber,
  });
  return res.data;
};

const checkIsUserEmailExisted = async (requestData: any) => {
  const { email, phoneNo } = requestData;

  const url = `/redCash/auth/api/v1.0/user/checkEmailExisted/email/${email}/phoneNo/${phoneNo}`;

  const res = await authSetupAxiosInstance.post(url);
  return res.data;
};

const createSmeUser = async (requestData: any) => {
  const url = `/redCash/auth/api/v1.0/smeUser`;

  const res = await authSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const checkIsSmePartnerIdExisted = async (id: string) => {
  const url = `/redCash/auth/api/v1.0/smeUser/partner/${id}`;
  const res = await authSetupAxiosInstance.get(url);
  return res.data;
};

const userService = {
  checkValidPhoneNo,
  login,
  redCashLogin,
  getCurrentUser,
  createUser,
  createRedCashAccount,
  updateUser,
  updateUserAuthOnly,
  updateUserEmail,
  resetPassword,
  changePassword,
  getTrustGateUserCertInfo,
  enrolTrustGateUserCertInfo,
  deleteUser,
  getUserDetailsByAccessToken,
  requestToResetEmail,
  checkResetPasswordToken,
  updateUserPassword,
  patchUserApplicationCreated,
  checkUserTinNo,
  checkIsUserEmailExisted,
  createSmeUser,
  checkIsSmePartnerIdExisted,
};

export default userService;
