import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { CreateApplicationDraftPayload } from "../../features/application/applicationSlice";

const getSmeAgreementById = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/api/v1.0/smeAgreement/${id}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const signSmeAgreement = async (requestData: any) => {
  const { agreementId, payload, language } = requestData;

  const url = `/redCash/api/v1.0/sme/pdf/sign/agreement/language/${language}/agreement/${agreementId}`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const res = await beSetupAxiosInstance.post(url, payload, config);
  return res.data;
};

const getSmeAgreementStepStatus = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/api/v1.0/smeAgreementStep/id/${id}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const smeAgreementService = {
  getSmeAgreementById,
  signSmeAgreement,
  getSmeAgreementStepStatus,
};

export default smeAgreementService;
