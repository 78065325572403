import { createSlice } from "@reduxjs/toolkit";

import {
  getAgreementDetailsThunk,
  getAgreementStepStatusThunk,
  getRepaymentScheduleListThunk,
} from "../../services/agreementService/agreementThunk";
import { UploadedFile } from "../application/applicationSlice";
import { getFilesByEntityIdThunk } from "../../services/fileService/fileThunk";
import groupBy from "lodash.groupby";
import {
  getSmeAgreementByIdThunk,
  getSmeAgreementStepStatusThunk,
} from "../../services/smeAgreementService/smeAgreementThunk";

export type Attestation = {
  agreementId: string;
  attestationStatus: boolean;
  liveAttestationDateTime: string;
  requestedLiveAttestationDate: string;
  requestedLiveAttestationTime: string;
  liveAttestationRequest: boolean;
  videoAttestation: boolean;
};

export type AgreementStepStatus = {
  id: string;
  code:
    | "LOANAPPLICATIONAPPROVED"
    | "ATTESTATIONOPTION"
    | "ATTESTATIONCOMPLETED"
    | "AGREEMENTESIGNING"
    | "REPAYMENTOPTION"
    | string;

  name: string;
  status: boolean;
  aggreementId: string;
  createdAt: string;
  updatedAt: string;
};

export type SmeAgreement = {
  id: string;
  smeApplicationId?: string;
  attestationId?: string;
  attestation?: Attestation;
  agreementStepStatusId?: string;
  agreementNo: string;
  status:
    | "PENDING"
    | "DONE_VIDEO_ATTESTATION"
    | "DONE_LIVE_ATTESTATION"
    | "REQUESTED_FOR_LIVE_ATTESTATION"
    | "PENDING_SIGNING"
    | "PENDING_DISBURSEMENT"
    | string;
  loanTenure?: number;
  loanInterestRate?: number;
  monthlyInstalmentDay?: number;
  monthlyInstalmentAmount?: number;
  lastInstalmentAmount?: number;
  loanPayableAmount?: number;
  signDate?: string;
  repaymentOption?: string;
  processingFee?: number;
  firstInstalmentDate?: string;
  createdAt?: string;
  updatedAt?: string;
  disbursementPostedDate?: string;
  protectedSignedAgreementFileId?: string;
  eStampingCertificateFileId?: string;
  clickedDownloadEStampingCert?: boolean;
  approveUploadedEStampingCert?: boolean;
  smeAttetstationId?: string;
};

export interface TenureDetails {
  instalmentNo?: number;
  outstandingAmount?: number;
}

export type AgreementState = {
  agreementDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: SmeAgreement;
  };
  agreementStepStatus: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: AgreementStepStatus[];
  };
  agreementSignature?: string;
  agreementFiles: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: {
      signedagreementpdf?: UploadedFile[];
      estamping?: UploadedFile[];
    };
  };
  repaymentScheduleList: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: TenureDetails[];
  };
  otpSent?: boolean;
};

const initialState: AgreementState = {
  agreementDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
  agreementStepStatus: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  agreementFiles: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      signedagreementpdf: [],
      estamping: [],
    },
  },
  repaymentScheduleList: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  agreementSignature: undefined,
  otpSent: false,
};

export const smeAgreementSlice = createSlice({
  name: "smeAgreement",
  initialState,
  reducers: {
    setSmeAgreementDetails: (state, action) => {
      state.agreementDetails.data = action.payload;
      console.log("agreement", state.agreementDetails.data);
    },
    setAgreementStatus: (state, action) => {
      // @ts-ignore
      state.agreementDetails.data.agreementStatus = action.payload;
    },
    setAgreementSignatureStatus: (state, action) => {
      state.agreementSignature = action.payload;
    },
    setAgreementOtpSent: (state, action) => {
      state.otpSent = action.payload;
    },
    agreementStateReset: () => initialState,
    agreementSignatureStateReset: (state) => {
      state.agreementSignature = undefined;
    },
    resetSmeAgreementState: (state, action) => {
      state.agreementDetails = initialState.agreementDetails;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSmeAgreementByIdThunk.pending, (state) => {
        state.agreementDetails.isLoading = true;
      })
      .addCase(getSmeAgreementByIdThunk.fulfilled, (state, action) => {
        state.agreementDetails.isLoading = false;
        state.agreementDetails.data = action.payload;
      })
      .addCase(getSmeAgreementByIdThunk.rejected, (state, action) => {
        state.agreementDetails.isLoading = false;
        state.agreementDetails.isError = true;
        state.agreementDetails.errorMessage = action.payload;
        state.agreementDetails = initialState.agreementDetails;
      });

    builder
      .addCase(getSmeAgreementStepStatusThunk.pending, (state) => {
        state.agreementStepStatus.isLoading = true;
      })
      .addCase(getSmeAgreementStepStatusThunk.fulfilled, (state, action) => {
        state.agreementStepStatus.isLoading = false;
        state.agreementStepStatus.data = action.payload;
      })
      .addCase(getSmeAgreementStepStatusThunk.rejected, (state, action) => {
        state.agreementStepStatus.isLoading = false;
        state.agreementStepStatus.isError = true;
        state.agreementStepStatus.errorMessage = action.payload;
      });

    builder
      .addCase(getFilesByEntityIdThunk.pending, (state) => {
        state.agreementFiles.isLoading = true;
      })
      .addCase(getFilesByEntityIdThunk.fulfilled, (state, action) => {
        state.agreementFiles.isLoading = false;
        state.agreementFiles.data = groupBy(
          action.payload,
          (file: UploadedFile) => file.fileType.name.toLowerCase(),
        );
      })
      .addCase(getFilesByEntityIdThunk.rejected, (state, action) => {
        state.agreementFiles.isLoading = false;
        state.agreementFiles.isError = true;
        state.agreementFiles.errorMessage = action.payload;
      });

    builder
      .addCase(getRepaymentScheduleListThunk.pending, (state) => {
        state.repaymentScheduleList.isLoading = true;
      })
      .addCase(getRepaymentScheduleListThunk.fulfilled, (state, action) => {
        state.repaymentScheduleList.isLoading = false;
        state.repaymentScheduleList.data = action.payload?.tenureDetails;
      })
      .addCase(getRepaymentScheduleListThunk.rejected, (state, action) => {
        state.repaymentScheduleList.isLoading = false;
        state.repaymentScheduleList.isError = true;
        state.repaymentScheduleList.errorMessage = action.payload;
      });
  },
});

export const {
  resetSmeAgreementState,
  agreementSignatureStateReset,
  setSmeAgreementDetails,
  setAgreementStatus,
  setAgreementSignatureStatus,
  setAgreementOtpSent,
} = smeAgreementSlice.actions;

export default smeAgreementSlice.reducer;
