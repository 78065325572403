import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import smeAgreementService from "./smeAgreementService";
import agreementService from "../agreementService/agreementService";

export const getSmeAgreementByIdThunk = createAsyncThunk(
  "sme/agreement",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAgreementService.getSmeAgreementById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const signSmeAgreementThunk = createAsyncThunk(
  "sme/agreement/signAgreement",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAgreementService.signSmeAgreement(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSmeAgreementStepStatusThunk = createAsyncThunk(
  "sme/agreement/getStepStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAgreementService.getSmeAgreementStepStatus(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
